import { useStore } from "@/store";

export function getRelativePath(path) {
  const store = useStore();
  const { country, lang, isGlobalWeb } = store;

  let rPath = path;
  // 对于中文不需要前缀处理
  if (country !== "cn") {
    rPath = `${isGlobalWeb ? "/global" : ""}${country ? "/" + country : ""}${path == "/" ? "" : path}`;
  }
  return rPath;
}
