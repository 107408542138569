<template>
  <div
    class="modal fade login-modal in"
    id="loginModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    data-backdrop="static"
    style="display: block; padding-right: 15px"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="login-close" @click="hideLogin">
          <img src="https://course-data-img.babybus.com/CourseData/CoursePic/20220803/c62b9d9f18d04d22833e656354be5886.png" />
        </div>

        <div class="content-main content-code" v-show="!isShowPhoneLogin">
          <div id="login_container">
            <iframe
              src="https://open.weixin.qq.com/connect/qrconnect?appid=wx2713ec5ae8cbe28e&amp;scope=snsapi_login&amp;redirect_uri=https%3A%2F%2Fudb.babybus.com%2FOAuth%2FWxLogin&amp;state=4023&amp;login_type=jssdk&amp;self_redirect=true&amp;styletype=&amp;sizetype=&amp;bgcolor=&amp;rst=&amp;style=black&amp;href=https://picfile-baidu.babybus.com/ToolCenter/PromoteFile/5E1FCB48-B27D-605E-412D-B2FCA0A9E82D.css"
              frameborder="0"
              scrolling="no"
              width="300px"
              height="400px"
            ></iframe>
          </div>
        </div>
        <div class="content-main content-phone" v-show="isShowPhoneLogin">
          <p class="title">登录/注册</p>
          <p class="sub-title">登录获取资源下载特权</p>
          <form class="form">
            <div class="form-group">
              <div class="group-bd phone">
                <input placeholder="请输入手机号" @input="onInputPhone" type="text" class="input-model bb-input" maxlength="11" />
                <div class="mask"></div>
              </div>
              <div class="group-tip phone" v-if="showPhoneError">请输入正确的手机号</div>
            </div>
            <div class="form-group">
              <div class="group-bd code">
                <input placeholder="请输入验证码" @input="onInputCode" type="text" name="code" class="input-model bb-input" />
                <div class="mask"></div>
              </div>
              <div class="group-tip code" v-if="showCodeError">验证码填写错误</div>
              <div class="group-hd no-phone" :class="onGetCode ? 'no-click' : 'pointer'" @click="onTapCode">
                {{ onGetCode ? time + "s" : "获取验证码" }}
              </div>
            </div>
            <!-- :disabled="phone.length && code.length?'':'disabled'" -->
            <div class="phone-submit" :class="!!phone.length && code.length >= 6 && !showPhoneError ? '' : 'disabled'" @click="loginPhone">登 录</div>
          </form>
          <p class="user-agreement">
            未注册手机验证后自动登录，注册即代表同意宝宝巴士童书<a :href="getProtocol('agreement', store.lang)" target="_blank">《用户协议》</a>和
            <a :href="getProtocol('privacy', store.lang)" target="_blank">《隐私政策》</a>
          </p>
        </div>
      </div>

      <!-- 点击微信登录 -->
      <div class="switch-login use-wechat-login" @click="showWeixinLogin" v-show="isShowPhoneLogin" v-if="!isRegister">
        <img src="https://course-data-img.babybus.com/CourseData/CoursePic/20220803/54f25ed8e569474c8a3efe58aad3b11e.png" />
        <span>使用微信扫码登录</span>
      </div>

      <!-- 点击手机登录 -->
      <div class="switch-login use-phone-login" @click="showPhoneLogin" v-show="!isShowPhoneLogin">
        <img src="https://course-data-img.babybus.com/CourseData/CoursePic/20220803/ff05ce0689d743639f7ec526f0d2ab5a.png" />
        <span v-if="!isRegister">手机号登录/注册</span>
      </div>
    </div>
  </div>
</template>

<script>
import BusinessService from "@/network/service";
import { useStore } from "@/store";
import Cookies from "js-cookie";
import { getProtocol } from "@/network/config.js";
export default {
  name: "login",
  data() {
    return {
      isAlreadyShowModal: false,
      tooLow: false, //是否为过低浏览器
      isShowPhoneLogin: false,
      isRegister: false,
      showPhoneError: false,
      showCodeError: false,
      phone: "",
      code: "",
      time: 0,
      timer: {},
      onGetCode: false,
      sendTimer: null,
    };
  },
  setup() {
    const store = useStore();
    // ;
    return {
      store,
    };
  },
  components: {},
  methods: {
    getProtocol(type, lang) {
      return getProtocol(type, lang);
    },
    sendCaptcha() {
      if (this.sendTimer) return;
      this.sendTimer = setTimeout(() => {
        clearTimeout(this.sendTimer);
        this.sendTimer = null;
      }, 3000);
      BusinessService.sendCaptcha(this.phone)
        .then((res) => {
          console.log("发送验证码成功：", res);
          this.onGetCode = true;
          this.time = 60;
          this.timer = setTimeout(() => {
            this.time = this.time - 1;
            this.startCountDown();
          }, 1000);

          this.showToast("验证码已发送", "normal");
        })
        .catch((err) => {
          console.error("发送验证码失败：", err);
          if (err.resultCode == "Input_Phone") {
            this.showPhoneError = true;
          }
          this.showToast(err.resultMessage);
        });
    },
    //手机登录
    loginPhone() {
      BusinessService.loginPhone(this.phone, this.code)
        .then((res) => {
          console.log("手机登录成功：", res);
          let userInfo = res.accountInfo;
          const store = useStore();
          store.userInfo = userInfo;
          store.showLogin = false;
        })
        .catch((err) => {
          console.error("手机登录失败：", err);
          if (err.resultCode == "Captcha_Failure") {
            console.log("验证码");
          }
          this.showToast(err.resultMessage);
        });
    },
    getLoginResult() {
      BusinessService.getLoginResult()
        .then((res) => {
          console.log("getLoginResult Success:", res);
          console.log("登录成功");
          let userInfo = {
            accountID: Cookies.get("AccountID"),
            nickName: Cookies.get("UserName"),
            avatarUrl: Cookies.get("AvatarUrl"),
          };

          // 读取不到cookie的时候从接口返回处拿
          if (!userInfo.accountID && res.accountInfo) {
            userInfo = res.accountInfo;
          }
          const store = useStore();
          store.userInfo = userInfo;
          store.showLogin = false;
        })
        .catch((err) => {
          console.error("getLoginResult Error:", err);
          this.showToast(err.resultMessage);
        });
    },
    showPhoneLogin() {
      this.isShowPhoneLogin = true;
    },
    showWeixinLogin() {
      this.isShowPhoneLogin = false;
    },
    onInputPhone(e) {
      this.phone = this.convertToHalfWidthPhoneNumber(e.target.value);
      if (e.target.value.length > 0 && e.target.value.length != 11) {
        this.showPhoneError = true;
      } else {
        this.showPhoneError = false;
      }
    },
    onInputCode(e) {
      this.code = this.convertToHalfWidthPhoneNumber(e.target.value);
    },
    onTapCode() {
      if (this.onGetCode) {
        return;
      }
      this.sendCaptcha();
    },
    startCountDown() {
      clearTimeout(this.timer);
      if (this.time <= 0) {
        this.onGetCode = false;
        return;
      }
      this.timer = setTimeout(() => {
        this.time = this.time - 1;
        this.startCountDown();
      }, 1000);
    },
    isTooLow() {
      var theUA = window.navigator.userAgent.toLowerCase();
      if ((theUA.match(/msie\s\d+/) && theUA.match(/msie\s\d+/)[0]) || (theUA.match(/trident\s?\d+/) && theUA.match(/trident\s?\d+/)[0])) {
        var ieVersion = theUA.match(/msie\s\d+/)[0].match(/\d+/)[0] || theUA.match(/trident\s?\d+/)[0];
        if (ieVersion <= 9) {
          this.tooLow = true;
        }
      }
    },
    initListener() {
      window.addEventListener("message", this.loginListener);
    },

    loginListener(event) {
      if (event.data == "LoginSuccess") {
        this.getLoginResult();
        console.log("登录成功", event);
      } else if (event.data == "Register") {
        // this.isRegister = true;
        // this.isShowPhoneLogin = true;
        console.log("扫码成功，需要注册...");
      } else if (event.data == "onAgreementClick") {
        console.log("点击用户协议...");
        this.onTapAgree();
      } else if (event.data == "onPrivacyClick") {
        console.log("点击隐私政策...");
        this.onTapPrivacy();
      } else {
        //console.log("login message error:", event);
      }
    },
    hideLogin() {
      const store = useStore();
      store.showLogin = false;
    },
    showToast(msg, type = "error") {
      const store = useStore();
      store.toast = {
        isShow: true,
        msg: msg || this.$t("netError"),
        type,
      };
    },
    noWheel() {
      const store = useStore();
      store.isNoWheel = true;
    },
    canWheel() {
      const store = useStore();
      store.isNoWheel = false;
    },
    convertToHalfWidthPhoneNumber(phoneNumber) {
      let halfWidthPhoneNumber = "";
      for (let i = 0; i < phoneNumber.length; i++) {
        let charCode = phoneNumber.charCodeAt(i);
        if (charCode >= 65296 && charCode <= 65305) {
          // 检查是否为全角数字
          let halfWidthChar = String.fromCharCode(charCode - 65248); // 转换为半角数字
          halfWidthPhoneNumber += halfWidthChar;
        } else {
          halfWidthPhoneNumber += phoneNumber.charAt(i);
        }
      }
      return halfWidthPhoneNumber;
    },
  },
  mounted() {
    this.isTooLow();
    this.initListener();
    this.noWheel();
  },
  unmounted() {
    if (this.timer) clearTimeout(this.timer);
    this.canWheel();
  },
  beforeUnmount() {
    window.removeEventListener("message", this.loginListener);
  },
};
</script>

<style lang="less" scoped>
p {
  margin: 0;
  padding: 0;
}
/*登录框*/
.login-modal .modal-dialog {
  margin-top: -227px;
  width: 360px;
  height: 448px !important;
  top: 50%;
  margin-left: -180px;
  left: 50%;
  position: absolute;
  border-radius: 8px;
}

.login-modal .modal-content {
  /*background: url('https://dingfile.babybus.com/DingFile/DingBusFile/20201209/98c8047a88854a3f9677530608d0bd95.png') no-repeat center / cover;*/
  border-radius: 20px;
  width: 360px;
  height: 393px;
  background-color: #fff;
}

.login-modal .modal-content .login-close {
  position: absolute;
  width: 32px;
  height: 32px;
  right: 11px;
  top: 10px;
  line-height: 1;
  cursor: pointer;
}

.login-modal .modal-content .login-close img {
  vertical-align: inherit;
  width: 100%;
  height: 100%;
}

.login-modal .modal-content .content-main {
  overflow: hidden;
  border-radius: 8px;
}

.switch-login {
  width: 100%;
  height: 60px;
  // background: #f6f6f6;
  border-radius: 0px 0px 8px 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.switch-login span {
  font-size: 16px;
  font-weight: 400;
  color: rgba(25, 31, 37, 0.72);
}

.switch-login img {
  width: 32px;
  height: 32px;
}

.login-modal .modal-content .content-main.content-code .title {
  font-size: 22px;
  font-weight: bold;
  color: #191f25;
  line-height: 1;
  text-align: center;
  margin-top: 44px;
}

.login-modal .modal-content .content-main.content-code .sub-title {
  font-size: 16px;
  font-weight: 400;
  color: #999999;
  margin: 14px 0 20px;
  text-align: center;
  line-height: 1;
}

.login-modal .modal-content .content-main.content-code .qr-code {
  width: 100%;
  height: 200px;
  /*background: #FFFFFF;*/
  /*border-radius: 4px;*/
  /*border: 1px solid rgba(25, 31, 37, 0.08);*/
  padding: 10px;
  margin: 0 auto;
  position: relative;
  background-image: url("https://educate-res.babybus.com/Educate/ResourceFile/20220412/47e63e8b9f3c42cfa38a2889ac68b01d.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.login-modal .modal-content .content-main.content-code .qr-code img {
  width: 200px;
  height: 100%;
  margin-left: 90px;
}

.login-modal .modal-content .content-main.content-code .qr-code img.active {
  -webkit-filter: blur(2px);
  filter: blur(2px);
}

.login-modal .modal-content .content-main.content-code .qr-code .refresh {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.56);
  border-radius: 4px;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  display: none;
}

.login-modal .modal-content .content-main.content-code .qr-code .refresh-text {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
}

.login-modal .modal-content .content-main.content-code .qr-code .refresh-btn {
  margin-top: 12px;
  width: 102px;
  height: 32px;
  background: #0089ff;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  border: none;
  border-radius: 16px;
}

.login-modal .modal-content .content-main.content-code .qr-code .refresh.active {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.login-modal .modal-content .content-main.content-code .sub-code {
  font-size: 16px;
  font-weight: 400;
  color: #999999;
  margin: 22px 0 33px;
  text-align: center;
  line-height: 1;
}

.login-modal .modal-content .content-main.content-phone .title {
  font-size: 20px;
  font-weight: bold;
  color: #191f25;
  line-height: 1;
  margin-top: 40px;
  padding-left: 20px;
  line-height: 28px;
}

.login-modal .modal-content .content-main.content-phone .sub-title {
  font-size: 16px;
  font-weight: 400;
  color: #999999;
  line-height: 1;
  margin: 4px 0 16px;
  padding-left: 20px;
  line-height: 22px;
}

.login-modal .modal-content .content-main.content-phone .form-group {
  width: 320px;
  height: 59px;
  margin: 0 auto 5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid rgba(25, 31, 37, 0.08);
  position: relative;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.login-modal .modal-content .content-main.content-phone .form-group .group-bd {
  height: 55px;
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.login-modal .modal-content .content-main.content-phone .form-group .group-bd input {
  width: 100%;
  height: 100%;
  padding: 21px 0 23px 0;
  color: #191f25;
  caret-color: #0089ff;
}

.login-modal .modal-content .content-main.content-phone .form-group .group-bd input:-webkit-autofill {
  -webkit-text-fill-color: #191f25 !important;
  background-color: transparent;
  -webkit-transition: background-color 50000s ease-in-out 0s;
  transition: background-color 50000s ease-in-out 0s;
}

.login-modal .modal-content .content-main.content-phone .form-group .group-bd .mask {
  width: 30px;
  height: 20px;
  z-index: 0;
  position: absolute;
  top: 23px;
  pointer-events: none;
  right: 0;
  background: -webkit-gradient(linear, right top, left top, from(#ffffff), to(rgba(255, 255, 255, 0)));
  background: -webkit-linear-gradient(right, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  background: -moz-linear-gradient(right, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}

.login-modal .modal-content .content-main.content-phone .form-group .group-bd.phone {
  width: 194px;
}

.login-modal .modal-content .content-main.content-phone .form-group .group-tip {
  line-height: 1;
  font-size: 16px;
  font-weight: 400;
  color: #f25643;
  top: 4px;
  position: relative;
  padding-left: 16px;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  white-space: nowrap;
}

.login-modal .modal-content .content-main.content-phone .form-group .group-tip.active {
  display: block;
}

.login-modal .modal-content .content-main.content-phone .form-group .group-hd {
  font-size: 16px;
  font-weight: 400;
  color: #3079b7;
  position: relative;
  margin-left: 16px;
  top: 4px;
  line-height: 1;
  right: 0;
  cursor: pointer;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  text-align: right;
  white-space: nowrap;
}

.login-modal .modal-content .content-main.content-phone .form-group .group-hd.no-click {
  color: rgba(25, 31, 37, 0.56) !important;
  pointer-events: none;
}

.login-modal .modal-content .content-main.content-phone .form-group .group-hd.no-phone {
  color: #009bf5;
  font-size: 16px;
}

.no-pointer {
  pointer-events: none;
}
.pointer {
  cursor: pointer;
}

.login-modal .modal-content .content-main.content-phone .form-group:last-of-type {
  margin-bottom: 0;
}

.login-modal .modal-content .content-main.content-phone .phone-submit {
  width: 308px;
  height: 39px;
  border-radius: 19.5px;
  outline: none;
  margin: 16px auto 0;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  line-height: 39px;
  border: none;
  display: block;
  background: #5cc8fa;
  cursor: pointer;
}

.login-modal .modal-content .content-main.content-phone .phone-submit.disabled {
  background: #caebfd;
  pointer-events: none;
}

.login-modal .modal-content .content-main.content-phone .user-agreement {
  padding: 0 30px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(25, 31, 37, 0.4);
  line-height: 18px;
  margin-top: 15px;
  margin-bottom: 26px;
}

.login-modal .modal-content .content-main.content-phone .user-agreement a {
  text-decoration: underline;
  color: #324664;
  cursor: pointer;
}

.login-modal .modal-content .content-main.content-phone .user-agreement a:hover {
  color: #324664;
}

.login-modal .loading {
  height: 180px;
  width: 180px;
  background-color: #cce7ff;
  position: absolute;
  top: 10px;
  left: 10px;
}

.login-modal .loading:after {
  content: "";
  width: 40px;
  height: 40px;
  -webkit-animation: circle infinite 1s linear;
  -moz-animation: circle infinite 1s linear;
  animation: circle infinite 1s linear;
  background-image: url("https://dingfile.babybus.com/DingFile/DingBusFile/20201214/2904b6ea85d44c29ba2d6ddbf916f6c9.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.login-modal iframe {
  width: 100%;
}

@-webkit-keyframes circle {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes circle {
  0% {
    -moz-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes circle {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.fade.in {
  opacity: 1;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.use-wechat-login {
  width: 200px;
  height: 60px;
  background: #68c269;
  border-radius: 30px;
  margin: 20px auto 0;
}
.use-wechat-login > span {
  font-size: 16px;
  font-weight: 400;
  font-family: PingFangSC-Regular, PingFang SC;
  line-height: 60px;
  color: #fff;
}

.use-phone-login {
  width: 192px;
  height: 60px;
  background: #5cc8fa;
  border-radius: 30px;
  margin: 20px auto 0;
}
.use-phone-login > span {
  font-size: 16px;
  font-weight: 400;
  font-family: PingFangSC-Regular, PingFang SC;
  line-height: 60px;
  color: #fff;
}
</style>
