import { createI18n } from "vue-i18n";
import ar from "@/lang/ar";
import en from "@/lang/en";
import ja from "@/lang/ja";
import zh from "@/lang/zh";

const message = {
  ar: ar,
  en: en,
  ja: ja,
  zh: zh,
};

const COUNTRY_LANG_MAP = {
  cn: "zh",
  jp: "ja",
  sa: "ar",
  ae: "ar",
};

const i18n = createI18n({
  locale: "zh", //默认是中文
  legacy: false, //解决报错的
  globalInjection: true, // 全局注册$t方法
  messages: message,
});

export { i18n, COUNTRY_LANG_MAP };
export default i18n;
