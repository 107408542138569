export default {
  // 阿拉伯语
  language: "العربية",
  title: "الموقع الرسمي لكتب الأطفال لبيبي باص",
  description: `تخطط شركة بيبي باص كتب الأطفال حسب الأعمار اعتمادا على الخبرات الوفيرة في المحتويات التنويرية لسنوات عديدة سعيا لإنشاء نظام كتب الأطفال "العمر+الموضوع" من أجل مساعدتهم على النمو في متعدد الأبعاد!`,
  logo: "https://picfile-baidu.babybus.com/BceFile/FileData/20240719/0422850bbbb44bf287457a44bdfce53e.png",
  联系我们: "اتصل بنا",
  帮助与服务: "مركز المساعدة",
  资源下载: "تنزيل الموارد",
  关于我们: "معلومات عنا",
  用户协议: "اتفاقية المستخدم",
  隐私政策: "سياسة خصوصية",
  下载: "تنزيل",

  // 面包屑
  我的位置: "موقعي",
  点读书: "كتب قابلة لقراءة باللمس",
  "如何使用点读包，点击立即查看": "انقر للتعرف على كيفية استخدام حزمة القراءة",

  // 详情
  下载全部: "تحميل الكل",
  更新日期: "تاريخ التحديث",
  文件大小: "حجم الملف",
  到底了哦: "لقد وصلت إلى أسفل الصفحة",
  点读书列表: "قائمة كتب قابلة لقراءة باللمس",
  点读包下载说明: "تعليمات تنزيل حزمة القراءة ",
  下载说明: "بعد اكتمال التنزيل، يرجى فك ضغط المجلد ونسخ جميع ملفات bnf إلى دليل الملفات حيث يتم تخزين حزمة القراءة. انقر لعرض تعليمات التنزيل.",
  MB: "ميجا بايت",
  GB: "جيجا بايت",
  "资源即将上线，敬请期待": "الملفات الصوتية ستكون متاحة قريبا، لذا ترقبوا ذلك",

  // 帮助中心
  宝宝巴士点读笔: "بيبي باص قلم قراءة باللمس",

  // page state
  netError: "خطأ في الشبكة. يرجى التحقق من شبكتك وحاول مرة أخرى",
  error: "حدث خطأ ما~قم بالتحديث للمحاولة مرة أخرى",
  nodata: "المحتوى غير متوفر، حاول تجربة محتوى آخر",

  // pagination
  上一页: "السابق",
  下一页: "التالي",
};
