export default {
  // 简体中文
  language: "简体中文",
  title: "宝宝巴士童书",
  description: "宝宝巴士凝结多年启蒙内容打造经验，为儿童量身定制分龄阅读童书，致力于打造“年龄+主题”的童书体系，助儿童多维提升成长力！",
  logo: "https://educate-res.babybus.com/Educate/ResourceFile/20220412/ce0d5915b018418fb89d703d5ff138a9.png",
  联系我们: "联系我们",
  帮助与服务: "帮助与服务",
  资源下载: "资源下载",
  关于我们: "关于我们",
  用户协议: "用户协议",
  隐私政策: "隐私政策",
  下载: "下载",

  // 面包屑
  我的位置: "我的位置",
  点读书: "点读书",
  "如何使用点读包，点击立即查看": " 如何使用点读包，点击立即查看",

  // 详情
  下载全部: "下载全部",
  更新日期: "更新日期",
  文件大小: "文件大小",
  到底了哦: "到底了哦~",
  点读书列表: "点读书列表",
  点读包下载说明: "点读包下载说明",
  下载说明: "下载完成后，请解压文件夹，把解压后的所有bnf文件拷贝到点读包存放的文件目录。点击查看下载说明。",
  MB: "MB",
  GB: "GB",
  "资源即将上线，敬请期待": "资源即将上线，敬请期待",

  // 帮助中心
  宝宝巴士点读笔: "宝宝巴士点读笔",

  // page state
  netError: "网络错误，请检查网络后重试",
  error: "出错了哦～刷新后试试吧",
  nodata: "找不到内容哦～ 换一个试试吧",

  // pagination
  上一页: "上一页",
  下一页: "下一页",
};
