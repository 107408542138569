export default {
  // 日本語
  language: "日本語",
  title: "ベビーバス　絵本",
  description: "ベビーバスは、お子さまの健やかな成長をサポートするために、年齢に合わせたテーマごとの絵本・児童書シリーズを展開しています。",
  logo: "https://picfile-baidu.babybus.com/BceFile/FileData/20241114/ede6eb00b8be4e4a87bd88213a9a64c0.png",
  联系我们: "お問い合わせ",
  帮助与服务: "使い方",
  资源下载: "ダウンロード",
  关于我们: "会社情報",
  用户协议: "利用規約",
  隐私政策: "プライバシーポリシー",
  下载: "ダウンロード",

  // 面包屑
  我的位置: "ナビ",
  点读书: "タッチペン対応絵本",
  "如何使用点读包，点击立即查看": "音声データの使用方法について",

  // 详情
  下载全部: "すべてをダウンロード",
  更新日期: "更新日",
  文件大小: "サイズ",
  到底了哦: "もうないよ！",
  点读书列表: "タッチペン対応絵本一覧",
  点读包下载说明: "音声データのダウンロードについて",
  下载说明: "ダウンロードしたファイルを解凍して、解凍したbnfファイルをすべてコピーして「book」フォルダに保存します。",
  MB: "MB",
  GB: "GB",
  "资源即将上线，敬请期待": "音声データを準備中、お楽しみに！",

  // 帮助中心
  宝宝巴士点读笔: "ベビーバス　タッチペン",

  // page state
  netError: "ネットワークエラーが発生しました。",
  error: "エラーが発生しました。もう一度お試しください。",
  nodata: "該当の内容がありません。条件を変更して再度検索してください。",

  // pagination
  上一页: "前へ",
  下一页: "次へ",
};
