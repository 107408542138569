<template>
  <div class="footer" :class="{ 'with-player': showPlayer }">
    <div v-if="store.isGlobalWeb" class="f_link">
      <a :href="getProtocol('about', store.country)" target="_blank">{{ $t("关于我们") }}</a> |
      <a :href="getProtocol('agreement', store.country)" target="_blank">{{ $t("用户协议") }}</a> |
      <a :href="getProtocol('privacy', store.country)" target="_blank">{{ $t("隐私政策") }}</a>
    </div>
    <div v-else class="f_link">
      <a href="https://www.babybus.com/home" target="_blank">关于我们</a> | <a href="https://www.babybus.com/policy?name=copyright" target="_blank">版权声明</a> |
      <a :href="getProtocol('agreement', store.lang)" target="_blank">用户协议</a> |
      <a :href="getProtocol('privacy', store.lang)" target="_blank">隐私政策</a>
    </div>
    <template v-if="store.isGlobalWeb">
      <div>Copyright © 2024 BabyBus Co.,Ltd. All Rights Reserved</div>
    </template>
    <template v-else>
      <div class="pub" @click="onTapPub">闽ICP备12010148号-1 闽公网安备 35010402351092号 闽网文（2020）2781-106号</div>
      <div>Copyright © 2024 BabyBus All Rights Reserved 宝宝巴士股份有限公司</div>
    </template>
  </div>
</template>

<script>
import { useStore } from "@/store";
import { getProtocol } from "@/network/config.js";
export default {
  name: "footer",
  props: {
    showPlayer: Boolean,
  },
  setup(props) {
    const store = useStore();
    const onTapPub = () => {
      window.open("https://beian.miit.gov.cn/");
    };
    return {
      store,
      getProtocol,
      onTapPub,
    };
  },
};
</script>

<style lang="less" scoped>
.footer {
  position: relative;
  width: 100%;
  // height: 112px;
  background-color: #5cc8fa;
  text-align: center;
  padding: 20px 0;
  &.with-player {
    padding-bottom: 74px;
  }

  .f_link {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 24px;

    a,
    div {
      display: inline-block;
      line-height: 1;
      color: white;
      cursor: pointer;
      margin: 0 8px;
      &:hover {
        text-decoration: underline !important;
      }
    }
  }

  > div {
    font-size: 14px;
    color: white;
    height: 24px;
    line-height: 24px;
  }
}

.height152 {
  height: 152px;
}

.pub {
  cursor: pointer;
}
</style>
