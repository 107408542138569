const getCookie = (cname) => {
  let name = cname + "=";
  let cookieArr = document.cookie.split(";");
  console.log("cookieArr:", cookieArr);
  for (let i = 0; i < cookieArr.length; i++) {
    let cookieStr = cookieArr[i];
    if (cookieStr.indexOf(name) != -1) return cookieStr.substring(name.length + 1, cookieStr.length);
  }
  return "";
};

// 将秒数换成时00: 00格式
const formatSeconds = (value) => {
  var secondTime = value; // 秒
  var minuteTime = 0; // 分
  var result;
  minuteTime = parseInt(secondTime / 60);
  secondTime = parseInt(secondTime % 60);
  minuteTime = minuteTime >= 10 ? minuteTime : "0" + minuteTime;
  secondTime = secondTime >= 10 ? secondTime : "0" + secondTime;
  result = minuteTime + ":" + secondTime;
  return result;
};

// 批量下载
var downloadFileArr = [];
function download(arr) {
  if (arr && arr.length) {
    downloadFileArr = downloadFileArr.concat(downloadFileArr, arr);
  }
  if (!downloadFileArr.length) return;

  downloadFile(downloadFileArr[0].fileUrl);
  downloadFileArr.shift();
  setTimeout(() => {
    download();
  }, 50);
}

//单文件下载
const downloadFile = (url) => {
  const iframe = document.createElement("iframe");
  iframe.style.display = "none"; // 防止影响页面
  iframe.style.height = 0; // 防止影响页面
  iframe.src = url;
  iframe.onerror = load();
  document.body.appendChild(iframe);
  // 这一行必须，iframe挂在到dom树上才会发请求
  // 2分钟之后删除
  setTimeout(() => {
    iframe.remove();
  }, 2 * 60 * 1000);
};
const load = (e) => {
  console.log("load:", e);
};
// 修改meta
const addMeta = (name, content) => {
  const meta = document.createElement("meta");
  meta.content = content;
  meta.name = name;
  document.getElementsByTagName("head")[0].appendChild(meta);
};

/**
 * 深拷贝（假定不存在undefine和函数对象）
 */
const deepCopy = (data) => {
  return JSON.parse(JSON.stringify(data));
};

export default {
  getCookie,
  formatSeconds,
  download,
  downloadFile,
  addMeta,
  deepCopy,
};
