const { COUNTRY_LANG_MAP } = require("@/lang");
const { default: en } = require("@/lang/en");

const ENV = process.env["VUE_APP_ENV"];

const ENV_CONFIG = {
  business: {
    host: {
      development: "https://bookpointapi.development.platform.babybus.com",
      staging: "https://bookpointapi.staging.platform.babybus.com",
      production: "https://bookpointapi.babybus.com",
    },
    host_global: {
      development: "https://bookpointapi.development.platform.babybus.com/Overseas",
      staging: "https://bookpointapi.staging.platform.babybus.com/Overseas",
      production: "https://bookpointapi-global.babybus.com/Overseas",
    },
  },
  appHelpCenter: {
    host: {
      development: "https://apphelpcenter.development.platform.babybus.com",
      staging: "https://apphelpcenter.staging.platform.babybus.com",
      production: "https://apphelpcenter.babybus.com",
    },
    host_global: {
      development: "https://apphelpcenter.development.platform.babybus.com",
      staging: "https://apphelpcenter.staging.platform.babybus.com",
      production: "https://apphelpcenter.babybus.com",
    },
  },
  agreementInfoUrl: "https://udb.babybus.com/AccountH5/AgreementInfo?ProductID=4023&verID=0",
  privacyServiceUrl: "https://udb.babybus.com/AccountH5/PrivacyService?ProductID=4023&verID=0",
  collectID: {
    book: {
      development: "cfa238bbc5914348b1be6ad3f1174534",
      staging: "c88cc560692a4689b7a59f93506a722a",
      production: "c88cc560692a4689b7a59f93506a722a",
    },
    audio: {
      development: "8831c6c6028f4a70a4c9b3fe07df9fed",
      staging: "9d84be336bea4f85a110ff57761d1bca",
      production: "9d84be336bea4f85a110ff57761d1bca",
    },
  },
  areaID: {
    book: {
      development: "04224aa0957c444eb82db132978011ea",
      staging: "04224aa0957c444eb82db132978011ea",
      production: "04224aa0957c444eb82db132978011ea",
    },
    book_global: {
      development: "8dbdac85fbff40d4b6a43f2597aea517",
      staging: "8dbdac85fbff40d4b6a43f2597aea517",
      production: "8dbdac85fbff40d4b6a43f2597aea517",
    },
  },
  pageID: {
    book: {
      development: "b0215898788b4a3582ccef615b0a79e7",
      staging: "b0215898788b4a3582ccef615b0a79e7",
      production: "b0215898788b4a3582ccef615b0a79e7",
    },
    book_global: {
      development: "4e86d9dc6e164d4eae7d5177aa81b84d",
      staging: "4e86d9dc6e164d4eae7d5177aa81b84d",
      production: "4e86d9dc6e164d4eae7d5177aa81b84d",
    },
  },
  usageID: {
    zh: {
      development: 1786,
      staging: 1837,
      production: 1837,
    },
    ar: {
      development: 1723,
      staging: 1726,
      production: 1726,
    },
    ja: {
      development: 1780,
      staging: 1832,
      production: 1832,
    },
    en: {
      development: 1782,
      staging: 1834,
      production: 1834,
    },
  },
  protocols: {
    agreement: {
      zh: "https://udb.babybus.com/AccountH5/AgreementInfo?ProductID=4023&verID=0",
      ar: "https://www.babybus.com/global/ar/agreement",
      ja: "https://www.babybus.co.jp/agreement",
    },
    privacy: {
      zh: "https://udb.babybus.com/AccountH5/PrivacyService?ProductID=4023&verID=0",
      ar: "https://www.babybus.com/global/ar/policy",
      ja: "https://www.babybus.co.jp/privacy",
    },
    about: {
      zh: "https://www.babybus.com/home",
      ar: "https://www.babybus.com/global/ar/about",
      ja: "https://www.babybus.co.jp/company",
    },
  },
  questionListCategories: {
    zh: [
      // {
      //   coverUrl: "https://iot.babybus.com/BookPoint/BookPic/20221129/718bd05cb2ab4fbdb98f4eb9b6bce26d.png",
      //   productCode: "A",
      //   productHelperCategoryCode: "A",
      //   productName: "宝宝巴士点读笔",
      // },
      {
        coverUrl: "https://iot.babybus.com/BookPoint/BookPic/20221129/38c33526eccb417585fc8ade6ebabe70.png",
        productCode: "G",
        productHelperCategoryCode: "G",
        productName: "宝宝巴士点读笔",
      },
    ],
    ar: [
      {
        coverUrl: "https://picfile-baidu.babybus.com/BceFile/FileData/20240723/5131074bec24475f9205baf7fa517977.png",
        productCode: "G1",
        productHelperCategoryCode: "G1",
        productName: "宝宝巴士点读笔",
      },
    ],
    en: [
      {
        coverUrl: "https://picfile-baidu.babybus.com/BceFile/FileData/20240723/5131074bec24475f9205baf7fa517977.png",
        productCode: "G1",
        productHelperCategoryCode: "G1",
        productName: "BabyBus Talking Pen",
      },
    ],
    ja: [
      {
        coverUrl: "https://picfile-baidu.babybus.com/BceFile/FileData/20240723/5131074bec24475f9205baf7fa517977.png",
        productCode: "G1",
        productHelperCategoryCode: "G1",
        productName: "ベビーバス　タッチペン",
      },
    ],
  },
};

const isGlobalWeb = /global/gi.test(location.pathname);

const getAreaID = function (type) {
  if (isGlobalWeb) {
    return ENV_CONFIG.areaID[type + "_global"][ENV];
  } else {
    return ENV_CONFIG.areaID[type][ENV];
  }
};

const getCollectID = function (type) {
  if (isGlobalWeb) {
    return "";
  } else {
    return ENV_CONFIG.collectID[type][ENV];
  }
};

const getPageID = function (type) {
  if (isGlobalWeb) {
    return ENV_CONFIG.pageID[type + "_global"][ENV];
  } else {
    return ENV_CONFIG.pageID[type][ENV];
  }
};

function getHost(type) {
  if (isGlobalWeb) {
    return ENV_CONFIG[type]["host_global"][ENV];
  } else {
    return ENV_CONFIG[type]["host"][ENV];
  }
}

function getUsageID(lang, country) {
  let r = ENV_CONFIG["usageID"][lang][ENV];
  if (country == "cn") {
    const CN_USAGE_IDS = {
      development: 867,
      staging: 867,
      production: 867,
    };
    return CN_USAGE_IDS[ENV];
  }
  return ENV_CONFIG["usageID"][lang][ENV];
}

function getProtocol(type, country) {
  let lang = COUNTRY_LANG_MAP[country];
  return ENV_CONFIG.protocols[type][lang];
}

function getProductQuestionList(lang, country) {
  let r = JSON.parse(JSON.stringify(ENV_CONFIG.questionListCategories[lang])) || [];
  // 奇葩需求，仅在中文站时，存在此分类
  if (country == "cn") {
    const CHINA_ONLY = {
      coverUrl: "https://iot.babybus.com/BookPoint/BookPic/20221129/718bd05cb2ab4fbdb98f4eb9b6bce26d.png",
      productCode: "A",
      productHelperCategoryCode: "A",
      productName: "宝宝巴士点读笔",
    };
    r.unshift(CHINA_ONLY);
  } else {
    r.forEach((item) => {
      item.productCode = "G1";
      item.productHelperCategoryCode = "G1";
    });
  }
  return r;
}

module.exports = {
  getAreaID,
  getCollectID,
  getPageID,
  getHost,
  getUsageID,
  getProtocol,
  getProductQuestionList,
};
