<template>
  <div class="wrapper-none">
    <img src="https://picfile-baidu.babybus.com/ActivityData/BabyBusFile/20220712/dfadf817e5d84332855e734f24eadff4.png" />
    <div class="text">页面找不到 换个内容试试吧</div>
  </div>
</template>

<script>
export default {
  name: "none",
  components: {},
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.wrapper-none {
  width: 1000px;
  background-color: #fff;
  margin-left: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wrapper-none img {
  width: 300px;
  height: 210px;
  margin-top: 80px;
}
.wrapper-none .text {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 22px;
  margin-top: 8px;
}
</style>
