<template>
  <div class="list-router-wrap">
    <router-link i :to="getRelativePath('/help-list')">
      <div class="list-router-main" v-rtl>
        <img class="icon-help" :src="require(`@/assets/images/icon_help.svg`)" alt="" />
        <div class="text">{{ $t("如何使用点读包，点击立即查看") }}</div>
        <img class="icon-arrow" :src="require(`@/assets/images/icon_arrow_right_black_16x16.svg`)" alt="" />
      </div>
    </router-link>
  </div>
</template>

<script>
import { useStore } from "@/store";
export default {
  name: "banner",
  components: {},
  data() {
    const store = useStore();
    return {
      store,
    };
  },
  mounted() {},
};
</script>

<style lang="less">
.list-router-wrap {
  height: 30px;
  display: flex;
  padding: 0 50px;
  margin-top: 30px;
  .list-router-main {
    border-radius: 8px;
    background: rgba(92, 200, 250, 0.12);
    height: 30px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #000e48;
    .icon-help {
      width: 40px;
      height: 40px;
      margin-top: -10px;
    }
    .text {
      margin: 0 2px 0 8px;
    }
    .icon-arrow {
      width: 16px;
      height: 16px;
    }
    &.rtl {
      .icon-arrow {
        transform: rotateZ(180deg);
      }
      .text {
        margin: 0 8px 0 2px;
      }
    }
  }
}
</style>
