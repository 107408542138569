<template>
  <div class="w_block" @click="onTapBookItem()">
    <div class="b_img">
      <Image :src="data.coverUrl" />
    </div>
    <div class="b_title">
      <div class="icon_series" v-rtl :style="data.albumTagFillColor ? `background:${data.albumTagFillColor}` : ''" v-if="data.isSeries == '1' || data.albumTag">
        {{ data.albumTag || "系列" }}
      </div>
      {{ data.bookName || data.albumName }}
    </div>
    <div class="b_describe">{{ data.subTitle }}</div>
    <div class="b_all">
      <section class="b_time">{{ formatDate(updateDate) }}</section>
      <section class="b_down item-action">
        <div class="action download-list" @click.stop="onTapDownload">
          <span v-if="!isDownloading">{{ $t("下载") }}</span>
          <div v-if="isDownloading" class="download-progress">
            <span>
              <img src="https://picfile-baidu.babybus.com/ToolCenter/PromoteFile/04FF741D-83F0-394D-2782-60242B17250B.gif" />
            </span>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import util from "@/utils/utils";
import BusinessService from "@/network/service";
import Image from "../image/image.vue";
import Cookies from "js-cookie";
import { useStore } from "@/store";
export default {
  name: "book-item",
  data() {
    return {
      isDownloading: false,
      updateDate: "",
      downloadTimer: null,
    };
  },
  setup() {
    const store = useStore();
    return {
      store,
    };
  },
  computed: {
    formatDate() {
      const store = useStore();
      return (str) => {
        const date = new Date(str);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() 返回的月份从 0 开始，所以要加 1
        const year = date.getFullYear();

        switch (store.lang) {
          case "ar": // 阿拉伯语
          case "en": // 英语
            return `${day}/${month}/${year}`;
          case "ja": // 日语
            return `${year}.${month}.${day}`;
          case "zh": // 中文
            return `${year}-${month}-${day}`;
          default: // 默认格式，避免意外情况
            return store.isGlobalWeb ? `${day}/${month}/${year}` : `${year}-${month}-${day}`;
        }
      };
    },
  },
  components: {
    Image,
  },
  props: {
    data: Object,
  },
  methods: {
    onTapBookItem() {
      console.log("onTapBookItem");
      this.$router.push({
        path: `${this.getRelativePath("/book")}/${this.data.albumID || this.data.bookID}-${this.data.isAlbum}`,
      });
    },
    onTapDownload() {
      if (this.data.zipUrl) {
        this.$router.push({
          path: `${this.getRelativePath("/book")}/${this.data.albumID || this.data.bookID}-${this.data.isAlbum}`,
        });
        return;
      }
      if (this.downloadTimer) return;
      this.downloadTimer = setTimeout(() => {
        clearTimeout(this.downloadTimer);
        this.downloadTimer = null;
      }, 2000);

      const store = useStore();
      const accountID = Cookies.get("AccountIDMD5") || store?.userInfo?.accountID;
      if (!accountID && !store.isGlobalWeb) {
        console.log("未登录");
        store.showLogin = true;
        return;
      }

      // if (this.data.isAlbum == "1") {
      this.albumDownload();
      // } else {
      //   this.bookDownload();
      // }
    },
    bookDownload() {
      this.isDownloading = true;
      BusinessService.getBookFileBatchUrl(this.data.bookID || this.data.objectID)
        .then((res) => {
          console.log("获取点读书列表页下载成功：", res);
          this.isDownloading = false;
          let fileArr = res;
          if (fileArr.length > 1) {
            this.$router.push({
              path: `/book/${this.data.albumID || this.data.bookID}-${this.data.isAlbum}`,
            });
          } else {
            util.download(fileArr);
            this.$emit("showDownloadSign", res[0].fileSize);
          }
        })
        .catch((err) => {
          console.log("获取点读书列表页下载失败：", err);
          this.isDownloading = false;
          this.showToast(err.resultMessage);
        });
    },
    albumDownload() {
      this.isDownloading = true;
      BusinessService.getAlbumZipFileUrl(this.data.albumID || this.data.objectID, this.store.lang)
        .then((res) => {
          console.log("获取点读包专辑列表页下载成功：", res);
          this.isDownloading = false;
          // if (res.length > 1) {
          //   this.$router.push({
          //     path: `/book/${this.data.albumID}-${this.data.isAlbum}`,
          //   });
          // } else {
          util.downloadFile(res.fileUrl);
          this.$emit("showDownloadSign", res.fileSize);
          // }
        })
        .catch((err) => {
          console.log("获取点读包专辑列表页下载失败：", err);
          this.isDownloading = false;
          this.showToast(err.resultMessage);
          //this.$emit("showDownloadSign");
        });
    },
    showToast(msg, type = "error") {
      const store = useStore();
      store.toast = {
        isShow: true,
        msg: msg || this.$t("netError"),
        type,
      };
    },
  },
  mounted() {
    this.updateDate = this.data.updateDate.split(" ")[0];
  },
};
</script>

<style lang="less" scoped>
.w_block {
  position: relative;
  width: 216px;
  height: 364px;
  margin: 6px;
  background: #ffffff;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
}
.w_block > .b_img {
  width: 216px;
  height: 216px;
}
.w_block > .b_title {
  width: 100%;
  padding: 0 18px;
  height: 44px;
  font-size: 16px;
  color: #333333;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  box-sizing: border-box;
  font-weight: bold;
}
.w_block:hover > .b_title {
  color: #009bf5;
}
.w_block > .b_describe {
  width: 100%;
  height: 34px;
  margin: 5px 0;
  padding: 0px 18px;
  font-size: 12px;
  color: #999999;
  line-height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  box-sizing: border-box;
}
.w_block > .b_all {
  padding: 17px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.w_block > .b_all > .b_time {
  font-size: 12px;
  color: #bbbbbb;
}
.w_block > .b_all > .b_down {
  min-width: 68px;
  height: 28px;
  font-size: 14px;
  line-height: 28px;
  background: #fa885a;
  border-radius: 8px;
  padding: 0 8px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
}
.w_block > .b_all > .b_down:hover {
  background: #f26636;
}
.icon_series {
  height: 16px;
  border-radius: 4px 16px 16px 4px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  background: #5cc8fa;
  color: #ffffff;
  line-height: 16px;
  text-align: center;
  display: inline-block;
  padding-right: 6px;
  padding-left: 4px;
  &.rtl {
    border-radius: 16px 4px 4px 16px;
  }
}
</style>
