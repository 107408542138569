import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import i18n from "@/lang";
import rtlDirective from "./directives/rtl";
import { getRelativePath } from "./mixins/rPath";

const app = createApp(App);
const pinia = createPinia();

app.directive("rtl", rtlDirective);
app.use(router).use(pinia).mount("#app");
app.use(i18n);

console.log = (function (log) {
  return process.env["VUE_APP_ENV"] == "production" ? function () {} : log;
})(console.log);

import { useStore } from "@/store";

const store = useStore();
let isSDKLoaded = false; // 标志变量，确保只加载一次
// 根据 `isGlobalWeb` 动态加载 SDK
function loadAnalyticsSDK() {
  return new Promise((resolve, reject) => {
    if (isSDKLoaded) {
      // 如果已经加载，直接返回成功
      resolve();
      return;
    }
    const script = document.createElement("script");
    script.src = store.isGlobalWeb
      ? "https://bbstaticcdn.babybus.com/analysis/bb-analysis-h5-global-11.7.9.min.js"
      : "https://bbstaticcdn.babybus.com/analysis/bb-analysis-h5-11.7.9.min.js";
    script.onload = () => {
      console.log("Analytics SDK Loaded");
      // SDK 初始化逻辑
      bbAnalysis.init({
        appKey: "BE5A49A1A8B8486FAD585B802EC4231B",
        appName: "童书官网",
        autoGetOpenID: true, // 宝宝巴士app端内H5，`autoGetOpenID`应设置为`false`，由于无法主动采集`客户端openID`，需要开发者通过客户端协议获取客户端头部中的OpenID字段后调用`setOpenID`接口手动设置，否则数据不会上报，所以请特别注意这个参数的设置
        chCode: "window",
        dataFlowAnalysis: false, // 是否开启app和H5的联动分析，用于跟踪用户从App到H5页面的转化过程，以及它们之间的用户行为等关联分析。可选配置项，默认false
        isDebugMode: process.env["VUE_APP_ENV"] != "production", // 前端字段，调试时开启，上线时关闭，详见“初始化配置参数说明”
        isDebug: process.env["VUE_APP_ENV"] != "production" ? 1 : 0, // 服务端字段，调试时开启，上线时关闭，详见“初始化配置参数说明”
        platform: 71,
        platform2: 18,
        productID: 2575, // 注意是分析系统使用的productID，而非业务的productID
        projectID: 567, // 注意是分析系统使用的projectID，而非业务的projectID
        verCode: "3.4.0",
        verID: 30400,
      });
      resolve();
    };
    script.onerror = () => reject(new Error("Analytics SDK loading failed"));
    document.head.appendChild(script);
  });
}
// loadAnalyticsSDK()
//   .then(() => {
//     isSDKLoaded = true;
//     console.log("SDK initialized successfully");
//   })
//   .catch((error) => {
//     console.error(error);
//   });

// 全局混入
app.mixin({
  methods: {
    getRelativePath,
  },
});
