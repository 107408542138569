import { createWebHistory, createRouter, RouterView } from "vue-router";
import list from "@/pages/list/list.vue";
import home from "@/pages/home/home.vue";
import protocol from "@/pages/protocol/protocol.vue";
import audioDetail from "@/pages/audio-detail/audio-detail.vue";
import bookDetail from "@/pages/book-detail/book-detail.vue";
import helperDetail from "@/pages/helper-detail/helper-detail.vue";
import helperList from "@/pages/helper-list/helper-list.vue";
import none from "@/pages/none/none.vue";

// layout view
import Home from "@/views/Home/Home";
import Helper from "@/views/Helper/Helper";

import { useStore } from "@/store";
import { i18n, COUNTRY_LANG_MAP } from "@/lang";

const baseUrl = "";

const routes = [
  // 国内路由配置
  {
    path: "/",
    name: "home",
    component: Home,
    children: [
      {
        path: "",
        component: home,
      },
    ],
  },
  {
    path: "/global",
    name: "global",
    component: Home,
    redirect: (to) => {
      return `/`;
    },
  },
  {
    path: "/list",
    name: "list",
    component: Home,
    children: [
      {
        path: "",
        component: list,
      },
    ],
  },
  {
    path: "/book/:id",
    name: "book-detail",
    component: Home,
    children: [
      {
        path: "",
        component: bookDetail,
      },
    ],
  },
  {
    path: "/audio/:id",
    name: "audio-detail",
    component: Home,
    children: [
      {
        path: "",
        component: audioDetail,
      },
    ],
  },
  {
    path: "/help/:url",
    name: "help-detail",
    component: Home,
    children: [
      {
        path: "",
        component: helperDetail,
      },
    ],
  },
  {
    path: "/help-list",
    name: "help-list",
    component: Home,
    children: [
      {
        path: "",
        component: helperList,
      },
    ],
  },
  {
    path: "/protocol/:url",
    name: "protocol",
    component: Home,
    children: [
      {
        path: "",
        component: protocol,
      },
    ],
  },
  {
    path: "/404",
    name: "404",
    component: Home,
    children: [
      {
        path: "",
        component: none,
      },
    ],
  },
  {
    path: "/:pathMatch(.*)",
    redirect: "/404",
  },

  // 海外路由配置
  {
    path: "/global/:country",
    component: RouterView,
    children: [
      {
        path: "",
        component: Home,
        redirect: (to) => {
          const country = to.params.country;
          // 非i18n语言包语言，跳回国内站点
          if (!Object.keys(COUNTRY_LANG_MAP).includes(country)) {
            if (country == "ar") {
              return to.path.replace("/global/ar", "/global/ae");
            } else {
              return `/`;
            }
          }
          return `/global/${country}/list`;
        },
      },
      {
        path: "list",
        name: "list-global",
        component: Home,
        children: [
          {
            path: "",
            component: list,
          },
        ],
      },
      {
        path: "book/:id",
        name: "book-detail-global",
        component: Home,
        children: [
          {
            path: "",
            component: bookDetail,
          },
        ],
      },
      {
        path: "audio/:id",
        name: "audio-detail-global",
        component: Home,
        children: [
          {
            path: "",
            component: audioDetail,
          },
        ],
      },
      {
        path: "help/:url",
        name: "help-detail-global",
        component: Home,
        children: [
          {
            path: "",
            component: helperDetail,
          },
        ],
      },
      {
        path: "help-list",
        name: "help-list-global",
        component: Home,
        children: [
          {
            path: "",
            component: helperList,
          },
        ],
      },
      {
        path: "protocol/:url",
        name: "protocol-global",
        component: Home,
        children: [
          {
            path: "",
            component: protocol,
          },
        ],
      },
      {
        path: "404",
        name: "404-global",
        component: Home,
        children: [
          {
            path: "",
            component: none,
          },
        ],
      },
      {
        path: ":pathMatch(.*)",
        redirect: "404",
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(baseUrl),
  routes,
  scrollBehavior(to, from, savedPosition) {
    console.log("savedPosition:", savedPosition);
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const store = useStore();
  const countryMatch = to.path.match(/^\/global\/([^\/?#]+)(?:[\/?#]|$)/);
  let country = countryMatch ? countryMatch[1] : "";
  country = country || "cn";
  const savedLang = sessionStorage.getItem(`${country}.lang`);
  // let lang = savedLang || (country == "cn" ? "zh" : country);
  let lang = savedLang || COUNTRY_LANG_MAP[country];

  // 检查 `country`，如果不在允许的国家列表中，则重定向到国内站
  const allowedCountries = Object.keys(COUNTRY_LANG_MAP);
  if (countryMatch && !allowedCountries.includes(country)) {
    if (country == "ar") {
      const newPath = to.path.replace("/global/ar", "/global/ae");
      return next({ path: newPath, replace: true });
    } else {
      return next("/"); // 重定向到国内站
    }
  }
  console.log("当前国家/地区", country);
  console.log("当前语言", lang);
  if (lang !== store.lang) {
    // 设置 i18n
    i18n.global.locale.value = lang;
    document.documentElement.lang = lang;
    document.documentElement.dir = lang === "ar" ? "rtl" : "ltr";
    store.lang = lang;
  }

  // 获取 i18n 的语言包词条
  const langMessages = i18n.global.messages.value[lang];
  document.title = langMessages.title || "宝宝巴士童书官网";
  let metaDescription = document.querySelector('meta[name="description"]');
  metaDescription.setAttribute("content", langMessages.description);
  store.country = country;
  next();
});

export default router;
