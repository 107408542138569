export default {
  // 英语
  language: "English",
  title: "BabyBus · Children's Books",
  description: "BabyBus is developing an age-appropriate series of themed picture books and children's books to support children's healthy growth.",
  logo: "https://picfile-baidu.babybus.com/BceFile/FileData/20241114/0fa8e3eb8ac1433aa7daf396fc4d039b.png",
  联系我们: "Contact Us",
  帮助与服务: "Support",
  资源下载: "Downloads",
  关于我们: "About Us",
  用户协议: "User Agreement",
  隐私政策: "Privacy Policy",
  下载: "Download",

  // 面包屑
  我的位置: "My Location",
  点读书: "Talking Books",
  "如何使用点读包，点击立即查看": "How to use audio, click to learn",

  // 详情
  下载全部: "Download All",
  更新日期: "Update Date",
  文件大小: "File Size",
  到底了哦: "You've reached the end",
  点读书列表: "Talking Book List",
  点读包下载说明: "How to download audio.",
  下载说明: "Unzip the downloaded file, copy all the unzipped bnf files and save them in the “book” folder. Click here to learn how to download.",
  MB: "MB",
  GB: "GB",
  "资源即将上线，敬请期待": "Audio will be available soon.",

  // 帮助中心
  宝宝巴士点读笔: "BabyBus Talking Pen",

  // page state
  netError: "Network error, please check the network and try again.",
  error: "An error has occurred. Please try again.",
  nodata: "No content found. Please check your search criteria and try again.",

  // pagination
  上一页: "Previous",
  下一页: "Next",
};
